import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Platform } from '@ionic/angular';
import { isPlatformBrowser } from '@angular/common';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      defineCustomElements(window);
      this.initializeApp();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Always enforce light theme
      document.body.classList.toggle('dark', false);
    });
  }
}
