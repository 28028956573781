export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDgyaIDiWt47MPxjwqdWAAoI1ij8ZG44d8",
    authDomain: "shair-398818.firebaseapp.com",
    projectId: "shair-398818",
    storageBucket: "shair-398818.appspot.com",
    messagingSenderId: "77596314527",
    appId: "1:77596314527:web:299e8e3411d6065f548baa",
    measurementId: "G-4YT39L0VYT"
  },
  apiUrl: "https://api.shair.nl",
  appUrl: "https://beta.shair.nl",
};
